<template>
  <!-- <a-spin :spinning="spinning" tip="Loading..."> -->
    <div id="EnvironmentDetails">
      <div class="header">
        <!-- 左上角头部开关 -->
        <div class="header-header">
          <div class="deviceName">
            <span v-show="initial">{{ instrumentName }}</span>
            <!-- 名称修改 -->
            <a-icon
              type="form"
              v-show="initial"
              @click="amendName"
              :style="{ fontSize: '20px', color: '#7682CE' }"
            />
            <a-input
              v-model="instrumentName"
              v-show="alter"
              ref="input"
              :maxLength="20"
            />
            <a-button v-show="alter" @click="amendCancel" type="primary" ghost>{{
              $t("details.a11")
            }}</a-button>
            <a-button
              v-show="alter"
              type="primary"
              @click="updateName"
              :loading="loading"
              >{{ $t("details.a12") }}</a-button
            >
          </div>        
        </div>

        <!-- 返回上一级 -->
        <a-icon
          type="close" 
          :style="{ fontSize: '20px', color: '#7682CE' }"
          @click="goDack"
        />
      </div>
      <div class="main">
        <div class="main-top">
          <div :class="mainCircle"> 
            <div class="main-circle-title">
                {{$t("EnvironmentDetails.a1")}}
              </div>
              <div class="main-circle-value">
                {{inVal}}
              </div>
              <div :class="circleStatus">
                  {{status}}
              </div>
          </div>  
        </div> 
        <div>   
          <!-- 曲线图 -->
          <div id="myChart" v-if="isShow" :style="{width: '100%', height: '400px'}"></div>
          <a-tabs default-active-key="1" @change="callback">
            <a-tab-pane key="1" :tab="switchStatus.signalName">
              <my-title :title="title11"></my-title>      
              <my-statusInfo :optInfo="switchStatus.statusInfo" :itemIndex="itemIndex" @transferData="getTransferData"></my-statusInfo>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="comment" @transferData="getCommentData"></my-table>
              <div class="main-schedule">
                <my-scheduleCtrl :changeState="scheduleCtrl"></my-scheduleCtrl>
              </div>
              <my-title :title="title2"></my-title> 
              <my-tableMS :monitor="switchStatus.monitor" :itemIndex="itemIndex" @transferData="getTransferMonitor"></my-tableMS>
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
            <a-tab-pane key="2" :tab="detectAnalogData.signalName" force-render>
              <my-title :title="title3"></my-title>      
              <my-measureInfo :deviceType="deviceType" :optInfo="detectAnalogData.measureInfo" @transferData="getTransferData"></my-measureInfo>
              <my-title :title="title2"></my-title>
              <my-upperAndLower :deviceType="deviceType" :monitor="detectAnalogData.monitor" @transferData="getTransferMonitor"></my-upperAndLower>
              <my-title :title="title1"></my-title>
              <my-table :commentIds="comment"></my-table>              
              <my-tableB :remark="remark"></my-tableB>
            </a-tab-pane>
          </a-tabs>
        </div>           
      </div>
      <div class="footer">
        <a-button type="primary" @click="onSetting">{{this.$t('EnvironmentDetails.a8')}}</a-button>
      </div>
    </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import lightTable from "../lightDetails/components/lightTableC";
import lightTableB from "../lightDetails/components/lightTableB";
import statusInfo from "../lightDetails/components/statusInfo";
import upperAndLower from "../common/detail/upperAndLower";
import measureInfo from "../lightDetails/components/dimmerStatusInfo";
import tableMS from "../lightDetails/components/lightTableMS";
import scheduleCtrl from "../lightDetails/components/scheduleCtrl";
import { getEnvironmentDetails, updateAnalogInputObj,updateBinaryData,updateDeviceName  } from "../../api/device";

export default {
  data() {
    return {
      initial: true,
      alter: false,
      initialname: "",
      isShow:true,
      spinning: false,
      loading: false,
      status: this.$t("EnvironmentDetails.a11"),
      scheduleCtrl: false,
      title1: this.$t("EnvironmentDetails.a3"),
      title2: this.$t("EnvironmentDetails.a7"),
      title3: this.$t("EnvironmentDetails.a6"),
      title11: this.$t('lightDetails.d9'),
      itemIndex:"1",
      inVal: "",
      comment:["","","","",""],
      instrumentName: this.$route.query.instrumentName,
      deviceType:this.$route.query.deviceType,// 270:气体泄漏探测器;410: 加湿器;420: 温度计;430: 湿度计;440: 水温计;450: 水位计;460: 流量计;470: 圧力计;650:风速; 660:风向; 670:噪声
      remark:{},
      monitor:{}, 
      measureInfo:{},
      detectAnalogData:{},     
      switchStatus:{},
      selectKey:"1",
    };
  },
  computed:{
    mainCircle() {
      return{
        main_circle_a:this.status==this.$t("EnvironmentDetails.a11"),
        main_circle_b:this.status==this.$t("EnvironmentDetails.a12"),
      }
    },
    circleStatus() {
      return{
        circle_status_a:this.status==this.$t("EnvironmentDetails.a11"),
        circle_status_b:this.status==this.$t("EnvironmentDetails.a12"),
      }
    }
  },
  mounted() {
    this.getData();    
  },
  methods: {
    // 修改名称时切换输入框的方法
    amendName() {
      this.initialname = this.instrumentName;
      this.initial = !this.initial;
      this.alter = !this.alter;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 5);
    },
    // 取消修改名称的方法
    amendCancel() {
      this.instrumentName = this.initialname;
      this.initial = !this.initial;
      this.alter = !this.alter;
    },
    // 修改名称的方法
    updateName() {
      this.loading = true;
      let par = {
          id:this.$route.query.id,//ID
          name: this.instrumentName,
      };
      console.log("updateLightName par:", par);
      updateDeviceName(par)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.errorCode == "00") {
            this.initial = !this.initial;
            this.alter = !this.alter;
            this.$message.success(res.msg);
          } else if (res.errorCode == "02") {
            this.$message.error(res.msg);
            this.amendCancel();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCommentData(msg){
      console.log("子组件传参内容 comment:",msg);
      this.comment = msg;
    },
    callback(key) {
      this.selectKey = key;
      this.refreshData();      
    },
    getTransferData(msg){
      console.log("子组件传参内容 measureInfo:",msg);
      this.measureInfo = msg;
    },
    getTransferMonitor(msg){
      console.log("子组件传参内容 monitor:",msg);
      this.monitor = msg;
    },
    // 页面后退
    goDack() {
      this.$router.go(-1);
    },
    // 页面开关操作方法
    onChange(checked) {
    },
    getData() {  
      this.spinning = true;    
      let siteId = this.$route.query.siteId;
      let analogPoints = this.$route.query.analogPoints;
      let digitalPoints=this.$route.query.digitalPoints;
      console.log("名称:"+this.instrumentName+"；站点:"+siteId+"；模拟信号："+analogPoints); 
      console.log("名称:"+this.instrumentName+"；站点:"+siteId+"；数字信号："+digitalPoints); 
      getEnvironmentDetails({ siteId,digitalPoints,analogPoints})
        .then((res) => {
          if(res!=null&&res.data!=null){
            this.detectAnalogData = res.data.detectAnalogData;  
            this.switchStatus=res.data.switchStatus;       
            this.refreshData();   
          } 
          this.spinning = false;          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err)
        });
    },
    refreshData() {   
      this.itemIndex=this.selectKey;        
      console.log("refreshData switchStatus:",this.switchStatus);   
      console.log("refreshData detectAnalogData:",this.detectAnalogData);   
      switch(this.selectKey){
        case "1"://状态
          if(this.switchStatus!=null&&this.switchStatus.statusInfo!=null){
            this.status=this.switchStatus.statusInfo.status; 
            this.trend=this.switchStatus.trendDatas;
            if(this.switchStatus.signalType!=null) 
              this.signal = this.switchStatus.signalType;
            if(this.switchStatus.tagPoint!=null)
              this.tagPoint=this.switchStatus.tagPoint; 
          }
          break;
        case "2"://模拟输入
          if(this.detectAnalogData!=null){
            this.inVal=this.detectAnalogData.inVal; 
            if(this.detectAnalogData.comment==null){
              this.comment = ["","", "","", ""];
            }
            else{
              this.comment =this.detectAnalogData.comment;
            }        
            console.log("refreshData comment:",this.comment); 
            this.remark = this.detectAnalogData.remark;
            this.monitor=this.detectAnalogData.monitor;   
            
            this.trend=this.detectAnalogData.trendDatas;
            if(this.detectAnalogData.signalType!=null)
              this.signal = this.detectAnalogData.signalType;
            if(this.detectAnalogData.tagPoint!=null)
              this.tagPoint=this.detectAnalogData.tagPoint; 
          }
          console.log("getEnvironmentDetails monitor:",this.detectAnalogData.monitor); 
          break;
      }   
      this.drawLine(this.trend, this.signal,this.tagPoint);             
    },
    onSetting(){
      switch(this.selectKey){
        case "1"://状态
          this.updateBinaryData(this.switchStatus.signalId,this.switchStatus.signalNo,1);
          break;
        case "2"://
          console.log("子组件传参内容 monitor:",this.monitor);
          if(this.monitor.maxMonitor){
            if(this.monitor.highLimit==null){
              this.monitor.highLimit=this.monitor.high;
            }
          }
          if(this.monitor.minMonitor){
            if(this.monitor.lowLimit==null){
              this.monitor.lowLimit=this.monitor.low;
            }
          }
          //先判断上下限设置数据是否符合
          if(this.monitor.highLimit<this.monitor.lowLimit){
            this.$message.error(this.$t('EnvironmentDetails.a9')); 
            return;
          }
          if(this.monitor.fixed>(this.monitor.highLimit-this.monitor.lowLimit)){
            this.$message.error(this.$t('EnvironmentDetails.a10'));
            return;
          }
          this.measureInfoSetting();
          break;
      }
      
    },
    updateBinaryData(signalId,signalNo,pointType){      
      this.loading = true;  
      let par = {
        signalId:signalId,
        signalNo:signalNo,
        pointType:pointType,
        statusInfo:this.measureInfo,
        comment:this.comment,
        monitor:this.monitor,
      }; 
      console.log("updateBinaryData par:", par);     
      updateBinaryData(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateBinaryData result", data);
          if(data)
            this.$message.info(this.$t('lightDetails.d14')); 
          else
            this.$message.error(this.$t('lightDetails.d15')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')); 
        }); 
    },
    measureInfoSetting(){      
      this.loading = true;  
      let par = {
        signalId:this.$route.query.analogPoints,
        measureInfo:this.measureInfo,
        comment:this.comment,
        monitor:this.monitor,
      }; 
      console.log("updateAnalogInputObj par:", par);     
      updateAnalogInputObj(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateAnalogInputObj result", data);
          if(data)
            this.$message.info(this.$t('lightDetails.d14')); 
          else
            this.$message.error(this.$t('lightDetails.d15')); 
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$message.error(this.$t('lightDetails.d15')); 
        }); 
    },
    chartResize(){
        try{
            let o = document.getElementById("myChart");
            if(o!=null){
                this.$echarts.init(document.getElementById("myChart")).resize();
            }
        }catch(e){
            console.log(e);
        } 
    },
    drawLine(trend,signal,tag) {
      if(trend==null)
        return;
      // 基于准备好的dom，初始化echarts实例
      console.log("drawLine signal",signal);
      console.log("drawLine trend",trend);     

      let myChart = this.$echarts.init(document.getElementById("myChart"));
      console.log("drawLine myChart:",myChart);
      let trendData = trend.data;
      let categories = trend.categories;
      let unit = trend.yunit+"/"+trend.xunit;
      // let tag = this.temperatureStatus.tagPoint;
      let sname = tag + "-" + trend.name + "-"+this.$t("energy.enedata139")+"（"+unit+"）";
      // 绘制图表
      let option = {
        title: {
          text: sname,
          subtext: trend.yunit,
          left: "6%",
          textStyle: {
            fontSize: 18,
            color: "#000",
            fontWeight: "bold"
          }
        },
        tooltip: {
          trigger: "axis",
           subtext: trend.yunit,
        },
        // x轴配置
        xAxis: {
          type: "category",
          data: categories
        },
        // y轴配置
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: signal,
            data: trendData,
            type: "line",
            smooth: true
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
  },
  components: {
    "my-title": tabletitle,
    "my-table": lightTable,
    "my-tableB": lightTableB,
    "my-upperAndLower": upperAndLower,
    "my-measureInfo": measureInfo,    
    "my-statusInfo":statusInfo,
    "my-tableMS":tableMS,
    "my-scheduleCtrl":scheduleCtrl,
  },
};
</script>

<style scoped>
#EnvironmentDetails {
  width: calc(100% - 40px);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #bdbcbc;
  background: #ffffff;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 20px 20px 20px 20px;
  border-bottom: 1px solid #cccdcd;
  white-space: nowrap;
  overflow: hidden;
}
.header-header {
  display: flex;
  align-items: center;
}
.deviceName{
  margin-left: 20px;
}
.deviceName span {
  /* color: #898989; */
  white-space: nowrap;
  margin-right: 20px;
}
.deviceName input {
  margin-right: 10px;
}
.deviceName button {
  margin-right: 10px;
}
.main {
  width: 100%;
  padding: 20px 20px 20px 20px;
  /* margin: 0 auto; */
}
.main-top {
  width:500px;
  margin: 0 auto;
}
.main_circle_a {
  padding:20px;
  width:180px;
  height:180px;
  border-radius: 50%;
  border:1px solid green; 
  display:inline-block;
  text-align:center;
  margin-left: 30%;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.main_circle_b {
  padding:20px;
  width:180px;
  height:180px;
  border-radius: 50%;
  border:1px solid red; 
  display:inline-block;
  text-align:center;
  margin-left: 30%;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.main-circle-title {
  font-size:13px;
  color:#898989;
  margin-top:20%;
}
.main-circle-value {
  font-size:30px;
}
.circle_status_a {
  font-size:13px;
  color:green;
  margin-bottom:30%;
}
.circle_status_b {
  font-size:13px;
  color:red;
  margin-bottom:30%;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}
</style>